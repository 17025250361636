var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": _vm.title,
      "close-on-click-modal": false,
      "width": "600px",
      "before-close": _vm.resetForm
    },
    on: {
      "open": _vm.initData
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "label-position": "left",
      "label-width": "100px",
      "model": _vm.formData
    }
  }, [_vm.type === 'company' ? _c('el-form-item', {
    attrs: {
      "label": "公司全称：",
      "prop": "fullName",
      "rules": [_vm.$formRules.required(), {
        validator: _vm.validateCompanyFullName,
        trigger: 'blur'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "fullName", $$v);
      },
      expression: "formData.fullName"
    }
  })], 1) : _vm.type === 'store' ? _c('el-form-item', {
    attrs: {
      "label": "渠道公司：",
      "required": "",
      "prop": "mechanismId",
      "rules": [_vm.$formRules.required()]
    }
  }, [_vm.companyId ? _c('el-input', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.companyInfo.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.companyInfo, "fullName", $$v);
      },
      expression: "companyInfo.fullName"
    }
  }) : _c('el-select', {
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "搜索渠道名称",
      "remote-method": _vm.companyRemoteMethod,
      "loading": _vm.companyLoading
    },
    model: {
      value: _vm.formData.mechanismId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "mechanismId", $$v);
      },
      expression: "formData.mechanismId"
    }
  }, _vm._l(_vm.companyList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "value": item.id,
        "label": "".concat(item.fullName, "\uFF08").concat(item.name, "\uFF09")
      }
    });
  }), 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "".concat(_vm.type === 'store' ? '门店/分部' : '简称', "\uFF1A"),
      "prop": "name",
      "rules": [_vm.$formRules.required(), {
        validator: _vm.validateCompanyName,
        trigger: 'blur'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "门店规模："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.storeNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "storeNumber", $$v);
      },
      expression: "formData.storeNumber"
    }
  }, _vm._l(_vm.storeScaleList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "人员规模："
    }
  }, [_c('el-select', {
    model: {
      value: _vm.formData.userNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userNumber", $$v);
      },
      expression: "formData.userNumber"
    }
  }, _vm._l(_vm.staffScaleList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "主营业务："
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.formData.mainBusiness,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "mainBusiness", $$v);
      },
      expression: "formData.mainBusiness"
    }
  }, _vm._l(_vm.businessList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.label,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "所在位置："
    }
  }, [_c('el-select', {
    staticClass: "channel-company-address-select",
    attrs: {
      "filterable": "",
      "remote": "",
      "reserve-keyword": "",
      "placeholder": "请输入关键词",
      "remote-method": _vm.addressRemoteMethod,
      "loading": _vm.addressLoading
    },
    on: {
      "change": _vm.handleAddressSelect
    },
    model: {
      value: _vm.addressModel,
      callback: function callback($$v) {
        _vm.addressModel = $$v;
      },
      expression: "addressModel"
    }
  }, [_c('div', {
    attrs: {
      "slot": "prefix",
      "width": "20px"
    },
    slot: "prefix"
  }, [_c('i', {
    staticClass: "iconfont icon-position"
  })]), _vm._l(_vm.addressList, function (item) {
    return _c('el-option', {
      key: item.id,
      staticClass: "channel-company-address-option",
      attrs: {
        "value": item.id,
        "label": item.label
      }
    }, [_c('div', {
      staticClass: "address-item"
    }, [_c('i', {
      staticClass: "iconfont icon-position"
    }), _c('div', {
      staticClass: "cont"
    }, [_c('p', [_vm._v(_vm._s(item.name))]), _c('span', [_vm._v(_vm._s(item.cityname) + _vm._s(item.adname) + _vm._s(item.address))])])])]);
  })], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "合作状态："
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, _vm._l(_vm.coopStatusList, function (item) {
    return _c('el-radio-button', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 1), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 仅“合作中”，才对外开放渠道经纪人注册、登录 ")])], 1), _c('el-divider'), _c('el-form-item', {
    attrs: {
      "label": "联系人："
    }
  }, [_c('ul', {
    staticClass: "contact-list"
  }, _vm._l(_vm.formData.concatList, function (item, index) {
    return _c('li', {
      key: item.key
    }, [_c('el-input', {
      staticClass: "name",
      attrs: {
        "placeholder": "姓名"
      },
      model: {
        value: item.name,
        callback: function callback($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    }), _c('el-input', {
      staticClass: "position",
      attrs: {
        "placeholder": "岗位"
      },
      model: {
        value: item.position,
        callback: function callback($$v) {
          _vm.$set(item, "position", $$v);
        },
        expression: "item.position"
      }
    }), _c('el-input', {
      staticClass: "phone",
      attrs: {
        "placeholder": "联系电话，可添加5个"
      },
      model: {
        value: item.phone,
        callback: function callback($$v) {
          _vm.$set(item, "phone", $$v);
        },
        expression: "item.phone"
      }
    }), _c('div', {
      staticClass: "remove-btn",
      on: {
        "click": function click($event) {
          return _vm.removeContact(index);
        }
      }
    }, [_c('i', {
      staticClass: "iconfont icon-reduce-fill"
    })])], 1);
  }), 0), _c('p', {
    staticClass: "tips",
    style: {
      paddingTop: !_vm.formData.concatList.length ? '3px' : ''
    }
  }, [_vm._v(" 可添加5个联系人，同个联系人，多组电话用逗号“,”分隔 ")]), _vm.formData.concatList.length < 5 ? _c('el-button', {
    staticClass: "mt10",
    attrs: {
      "type": "info"
    },
    on: {
      "click": _vm.addContact
    }
  }, [_vm._v("添加联系人")]) : _vm._e()], 1), _c('el-divider'), _c('el-form-item', {
    attrs: {
      "label": "渠道人员："
    }
  }, [_c('el-select', {
    attrs: {
      "multiple": "",
      "filterable": ""
    },
    model: {
      value: _vm.formData.userList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userList", $$v);
      },
      expression: "formData.userList"
    }
  }, _vm._l(_vm.staffList, function (item) {
    return _c('el-option', {
      key: item.userId,
      attrs: {
        "label": item.name,
        "value": item.userId
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "备注说明："
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.note,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "note", $$v);
      },
      expression: "formData.note"
    }
  })], 1)], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("取消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v("提交")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }