<template>
  <div>
    <div class="search-bar">
      <el-input
        placeholder="搜索渠道公司"
        v-model="queryData.keyword"
        class="input--append f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        v-model="queryData.channelUserList"
        placeholder="渠道人员"
        class="ml10"
        filterable
        clearable
        @change="search"
      >
        <el-option
          v-for="item in channelUserList"
          :label="item.name"
          :value="item.userId"
          :key="item.userId"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="queryData.targetType"
        placeholder="渠道来源"
        class="ml10"
        clearable
        @change="search"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in targetTypeList"
          :key="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="queryData.status"
        placeholder="合作状态"
        class="ml10"
        clearable
        @change="search"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in statusOptionArr"
          :key="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        class="input-date ml10"
        v-model="queryDate"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="search"
      >
      </el-date-picker>
      <el-dropdown class="ml10 mr10" @command="handleCommand">
        <el-button type="primary">
          创建渠道<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="company">渠道公司</el-dropdown-item>
          <el-dropdown-item command="store">门店/分部</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" @click="exportChannel" :loading="exportLoading"
        >全量导出</el-button
      >
    </div>
    <el-table
      border
      class="mt15"
      :row-class-name="tableRowClassName"
      :data="tableData"
      @selection-change="selectionChange"
      @sort-change="sortChange"
      @cell-click="cellClick"
      ref="multipleTable"
      row-key="id"
      lazy
      :load="loadStoreList"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column type="selection" width="55" fixed align="center">
      </el-table-column>
      <el-table-column prop="name" label="渠道名称">
        <template slot-scope="scope">
          <div
            class="blue pointer inline-block"
            @click.stop="openDetail(scope.row)"
          >
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="渠道来源">
        <template slot-scope="scope">
          {{ companyTypeMaps[scope.row.companyType] }}
        </template>
      </el-table-column>
      <el-table-column label="区域">
        <template slot-scope="{ row: { city, region } }">
          {{ city }}<span v-if="(city && region) || (!city && !region)">-</span
          >{{ region }}
        </template>
      </el-table-column>
      <el-table-column label="渠道人员">
        <template slot-scope="scope">
          <div v-if="scope.row.userList?.length">
            <span
              class="user-list"
              v-for="(item, index) in scope.row.userList"
              :key="index"
              >{{ item.name }}</span
            >
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="创建/邀请">
        <template
          slot-scope="{
            row: { companyType, inUserAuthName, inUserName, inviterUserName }
          }"
        >
          {{
            companyType === 0
              ? inUserAuthName || inUserName || "-"
              : inUserAuthName || inviterUserName || "-"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="inDate" label="创建/加入" sortable="custom">
        <template slot-scope="{ row: { inDate } }">
          {{ inDate | formatDate(7) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userCount"
        label="经纪人"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="totalReportCount"
        label="报备"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="totalTakeCount"
        label="带看"
        sortable="custom"
      ></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row: { status } }">
          <div :class="statusList[status].color">
            {{ statusList[status].name }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <div>
        <el-dropdown class="ml10" @command="handleStatus">
          <el-button type="primary">
            更新状态<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="item.value"
              :disabled="item.disabled"
              v-for="(item, index) in statusOptions"
              :key="index"
              >{{ item.text }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-button class="red ml10" @click="handleDelete">删除渠道</el-button>
      </div>
      <f-pagination
        :page-num.sync="queryData.pageNum"
        :page-size.sync="queryData.pageSize"
        :total="total"
        :fixed="false"
        @change="getList"
      ></f-pagination>
    </div>
    <!--    渠道详情-->
    <detail
      ref="detail"
      :title="dialogTitle"
      :visible.sync="detailDialog"
      :statusList="statusList"
      :detailId="detailId"
      :type="type"
      :companyType="companyType"
      @success="getList"
    ></detail>
    <!--    新增、编辑-->
    <c-company-edit
      :visible.sync="companyEditVisible"
      :type="type"
      @success="search"
    ></c-company-edit>
  </div>
</template>

<script>
import detail from "./_components/detail";
import CCompanyEdit from "./_components/edit";
import {
  getMechanismList,
  getMechanismUser,
  mechanismBatchStatus,
  mechanismBatchDelete,
  getStoreListByCompany
} from "@/api/channel";
import { BASE_URL } from "@/config";
import qs from "qs";
export default {
  components: { detail, CCompanyEdit },
  data() {
    return {
      queryData: {
        keyword: "",
        channelUserList: "",
        targetType: "",
        status: "",
        pageNum: 1,
        pageSize: 10
      },
      queryDate: "",
      channelUserList: [], //渠道人员
      targetTypeList: [
        { name: "自有渠道", id: 0 },
        { name: "PCN渠道", id: 2 }
      ], //渠道来源select
      statusList: [
        { name: "审核中", id: 0, color: "red" },
        { name: "合作中", id: 1, color: "green" },
        { name: "已暂停", id: 2, color: "orange" },
        { name: "待合作", id: 3, color: "red" },
        { name: "已关店", id: 4, color: "color9" }
      ], //合作状态select
      tableData: [],
      total: 0,
      multipleSelection: [], //多选数据
      companyTypeMaps: {
        0: "自有渠道",
        1: "-",
        2: "PCN渠道",
        3: "独立经纪"
      }, //渠道来源text
      statusOptions: [
        { text: "待合作", value: 3, disabled: false },
        { text: "合作中", value: 1, disabled: false },
        { text: "暂停合作", value: 2, disabled: false },
        { text: "已关店", value: 4, disabled: false }
      ], //合作状态handle
      companyEditVisible: false, //新增弹框
      detailDialog: false, //详情弹框
      dialogTitle: "", //详情标题
      detailId: "", //详情id
      type: "", //类型：company公司，store门店
      companyType: 0, //详情渠道类型
      exportLoading: false //导出按钮的loading
    };
  },
  computed: {
    //合作状态下拉筛选数据
    statusOptionArr() {
      let arr = this.$deepClone(this.statusList);
      arr.splice(0, 1);
      return arr;
    }
  },
  created() {
    this.getUserList();
    this.getList();
  },
  methods: {
    // 获取渠道人员列表
    async getUserList() {
      const res = await getMechanismUser(this.queryData);
      if (res) {
        this.channelUserList = res;
      }
    },
    // 获取渠道公司列表
    async getList() {
      const res = await getMechanismList(this.queryData);
      if (res) {
        this.tableData = res.list.map(item => {
          return { ...item, hasChildren: item.branchCount > 0 };
        });
        this.total = res.total;
        //更新子节点置空
        this.$set(
          this.$refs["multipleTable"].store.states,
          "lazyTreeNodeMap",
          {}
        );
        this.$set(this.$refs["multipleTable"].store.states, "treeData", {});
      }
    },
    //加载门店
    async loadStoreList(tree, treeNode, resolve) {
      const res = await getStoreListByCompany({ id: tree.id });
      if (res) {
        resolve(res);
      }
    },
    //筛选
    search() {
      this.queryData.pageNum = 1;
      this.queryData.inDateStart = "";
      this.queryData.inDateEnd = "";
      if (this.queryDate) {
        this.queryData.inDateStart = this.queryDate[0];
        this.queryData.inDateEnd = this.queryDate[1];
      }
      this.getList();
    },
    //排序
    sortChange({ prop, order }) {
      // sort 1 加入时间 2 近30天报备 3 近30天带看 4 经纪人数量 5 总报备 6 总带看
      // sortType 1 降序 0 升序
      let sortMap = {
        inDate: 1,
        userCount: 4,
        totalReportCount: 5,
        totalTakeCount: 6
      };
      Object.assign(this.queryData, {
        sort: order ? sortMap[prop] : "",
        sortType: order == "descending" ? 1 : order == "ascending" ? 0 : ""
      });
      this.getList();
    },
    //点击跳转创建
    handleCommand(command) {
      this.companyEditVisible = true;
      this.type = command;
    },
    //导出渠道
    exportChannel() {
      this.exportLoading = true;
      const { userId, token } = this.$userInfo;
      const { platformId } = this.$store.state.platformInfo;
      window.location.href = `${BASE_URL}/api/v1/platform/distribution/web/mechanism/company/list/export?${qs.stringify(
        {
          userId,
          token,
          platformId
        }
      )}`;
      setTimeout(() => {
        this.exportLoading = false;
      }, 1000);
    },
    // 给表格行添加className
    tableRowClassName({ row }) {
      //已关店颜色置灰
      if (row.status == 4) {
        return "row-close";
      }
    },
    //打开详情
    openDetail(row) {
      this.detailDialog = true;
      this.dialogTitle = row.companyType == 1 ? "门店/分部" : "渠道公司";
      this.detailId = row.id;
      this.type = row.companyType == 1 ? "store" : "company";
      this.companyType = row.companyType;
    },
    // 选中某一行
    cellClick(row, column) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.handleDisable();
    },
    // 选中的行
    async selectionChange(selection) {
      this.multipleSelection = selection;
      this.handleDisable();
    },
    //所选渠道包含“PCN渠道”时， 禁用“待合作”、“已关店”状态
    handleDisable() {
      let companyType = this.multipleSelection.some(item => {
        return item.companyType == 2;
      });
      this.statusOptions.forEach(item => {
        if ((item.value == 3 || item.value == 4) && companyType) {
          this.$set(item, "disabled", true);
        } else {
          this.$set(item, "disabled", false);
        }
      });
    },
    //批量修改渠道状态
    async handleStatus(e) {
      let ids = this.multipleSelection.map(item => item.id);
      const res = await mechanismBatchStatus({ idList: ids, status: e });
      if (res) {
        this.$showSuccess("操作成功");
        this.getList();
      }
    },
    //批量删除渠道
    handleDelete() {
      let ids = this.multipleSelection.map(item => item.id);
      this.$showConfirm(async () => {
        const res = await mechanismBatchDelete({ idList: ids });
        if (res) {
          this.$showSuccess("操作成功");
          this.getList();
        }
      }, "此操作将删除所选渠道且无法恢复，是否继续？");
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .search-bar {
  .input--append {
    width: 310px;
  }
  .el-select {
    width: 150px;
  }
  .input-date {
    width: 240px;
  }
}
::v-deep .row-close {
  * {
    color: #aaa;
  }
  .blue {
    opacity: 0.6;
  }
}
.user-list:not(:last-child)::after {
  content: ",";
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
::v-deep {
  .el-table__expand-icon .el-icon-arrow-right:before,
  .el-table__expand-icon--expanded .el-icon-arrow-right:before {
    content: "\e791";
    font-size: 16px;
    color: #7f7f7f;
  }
  .el-table tr td:nth-child(2) .cell,
  .el-table tr th:nth-child(2) .cell {
    padding-left: 16px;
  }
  .el-table__placeholder {
    width: 0;
  }
  .el-table [class*="el-table__row--level"] .el-table__expand-icon {
    margin-left: -22px;
  }
}
</style>
