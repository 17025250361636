<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :close-on-click-modal="false"
    width="600px"
    class="f-dialog"
    :before-close="resetForm"
    @open="initData"
  >
    <el-form
      ref="form"
      label-position="left"
      label-width="100px"
      :model="formData"
    >
      <el-form-item
        v-if="type === 'company'"
        label="公司全称："
        prop="fullName"
        :rules="[
          $formRules.required(),
          { validator: validateCompanyFullName, trigger: 'blur' }
        ]"
      >
        <el-input placeholder="请输入" v-model="formData.fullName"></el-input>
      </el-form-item>
      <el-form-item
        v-else-if="type === 'store'"
        label="渠道公司："
        required
        prop="mechanismId"
        :rules="[$formRules.required()]"
      >
        <el-input
          v-model="companyInfo.fullName"
          disabled
          v-if="companyId"
        ></el-input>
        <el-select
          v-else
          v-model="formData.mechanismId"
          filterable
          remote
          reserve-keyword
          placeholder="搜索渠道名称"
          :remote-method="companyRemoteMethod"
          :loading="companyLoading"
        >
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :value="item.id"
            :label="`${item.fullName}（${item.name}）`"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="`${type === 'store' ? '门店/分部' : '简称'}：`"
        prop="name"
        :rules="[
          $formRules.required(),
          { validator: validateCompanyName, trigger: 'blur' }
        ]"
      >
        <el-input placeholder="请输入" v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="门店规模：">
        <el-select v-model="formData.storeNumber">
          <el-option
            v-for="item of storeScaleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="人员规模：">
        <el-select v-model="formData.userNumber">
          <el-option
            v-for="item of staffScaleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主营业务：">
        <el-select v-model="formData.mainBusiness" multiple>
          <el-option
            v-for="item of businessList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在位置：">
        <el-select
          class="channel-company-address-select"
          v-model="addressModel"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="addressRemoteMethod"
          :loading="addressLoading"
          @change="handleAddressSelect"
        >
          <div width="20px" slot="prefix">
            <i class="iconfont icon-position"></i>
          </div>
          <el-option
            class="channel-company-address-option"
            v-for="item in addressList"
            :key="item.id"
            :value="item.id"
            :label="item.label"
          >
            <div class="address-item">
              <i class="iconfont icon-position"></i>
              <div class="cont">
                <p>{{ item.name }}</p>
                <span
                  >{{ item.cityname }}{{ item.adname }}{{ item.address }}</span
                >
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合作状态：">
        <el-radio-group v-model="formData.status">
          <el-radio-button
            v-for="item of coopStatusList"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
        <p class="tips">
          仅“合作中”，才对外开放渠道经纪人注册、登录
        </p>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="联系人：">
        <ul class="contact-list">
          <li v-for="(item, index) of formData.concatList" :key="item.key">
            <el-input
              class="name"
              placeholder="姓名"
              v-model="item.name"
            ></el-input>
            <el-input
              class="position"
              placeholder="岗位"
              v-model="item.position"
            ></el-input>
            <el-input
              class="phone"
              placeholder="联系电话，可添加5个"
              v-model="item.phone"
            ></el-input>
            <div class="remove-btn" @click="removeContact(index)">
              <i class="iconfont icon-reduce-fill"></i>
            </div>
          </li>
        </ul>
        <p
          class="tips"
          :style="{ paddingTop: !formData.concatList.length ? '3px' : '' }"
        >
          可添加5个联系人，同个联系人，多组电话用逗号“,”分隔
        </p>
        <el-button
          v-if="formData.concatList.length < 5"
          type="info"
          class="mt10"
          @click="addContact"
          >添加联系人</el-button
        >
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="渠道人员：">
        <el-select v-model="formData.userList" multiple filterable>
          <el-option
            v-for="item of staffList"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注说明：">
        <el-input
          v-model="formData.note"
          type="textarea"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestProxy } from "@/api/common";
import {
  getChannelCompanyDetail,
  getChannelStaffList,
  validateCompanyFullName,
  validateCompanyName,
  editChannelCompany,
  getMechanismList
} from "@/api/channel";
const storeScaleList = [
  "<3家",
  "3-5家",
  "6-10家",
  "11-20家",
  "21-50家",
  ">50家门店"
];
const staffScaleList = [
  "<20人",
  "20-50人",
  "51-100人",
  "100-200人",
  "101-500人",
  ">500人"
];
// 联系人
const contactItem = () => {
  return {
    morePhoneList: [],
    name: "",
    phone: "",
    key: Date.now()
  };
};

const formData = {
  address: "", //  地址
  addressImg: "", // 地址位置图
  city: "", // 城市 ,
  companyType: 0, // 0 为渠道 1为渠道下门店 ,
  concatList: [
    {
      morePhoneList: [],
      name: "",
      position: "",
      phone: ""
    }
  ], // 联系人
  fullName: "", // 全称
  id: null,
  location: "", // 经纬度 lon,lat
  mainBusiness: [], // 主营业务(新房、二手房、租赁) ,
  mechanismId: null, // 渠道公司ID ,
  name: "", // 简称
  note: "", // 备注
  region: "", // 区域
  status: 1, //  0审核中 1 待合作 2 合作中 3 已暂停 4 已关店
  storeNumber: "", // 门店数量
  userList: [], // 渠道人员 ,
  userNumber: "" // 人员规模
};

export default {
  props: {
    /**
     * @param {String} type 页面类型 可选值：company store 默认是公司
     * @param {String} companyId 渠道公司id 编辑公司和创建编辑门店时需要
     * @param {String} storeId 渠道公司下的门店id 编辑门店时需要
     * */
    type: {
      type: String,
      default: "company"
    },
    companyId: Number,
    storeId: Number
  },
  data() {
    return {
      // 门店规模
      storeScaleList: storeScaleList.map(s => ({ label: s, value: s })),
      // 员工规模
      staffScaleList: staffScaleList.map(s => ({ label: s, value: s })),
      // 合作状态
      coopStatusList: [
        {
          label: "合作中",
          value: 1
        },
        {
          label: "待合作",
          value: 3
        },
        {
          label: "暂停合作",
          value: 2
        },
        {
          label: "已关店",
          value: 4
        }
      ],
      // 主营业务
      businessList: [
        { label: "新房", value: "新房" },
        { label: "二手房", value: "二手房" },
        { label: "租赁", value: "租赁" }
      ],
      formData: this.$f.deepClone(formData),
      staffList: [], //渠道人员
      // 地址搜索
      addressList: [],
      addressModel: "",
      addressLoading: false, //地址搜索中
      // 公司搜索
      companyList: [],
      companyModel: "",
      companyLoading: false, //地址搜索中
      companyInfo: { fullName: "" } // 渠道公司信息
    };
  },
  computed: {
    title() {
      const { id } = this.formData;
      if (this.type === "store") {
        if (id) {
          return "编辑门店/分部";
        }
        return "新建门店/分部";
      }
      return `${id ? "编辑" : "新建"}渠道公司`;
    }
  },
  methods: {
    // 初始化数据
    initData() {
      const { storeId, companyId, type } = this;
      Object.assign(this.formData, {
        mechanismId: companyId,
        id: type === "store" ? storeId : companyId,
        companyType: type === "store" ? 1 : 0
      });

      // 获取渠道公司详情
      if (type === "store" && companyId) {
        this.getChannelCompanyDetail(companyId);
      }
      // 获取渠道公司 或 渠道公司门店详情
      if ((type === "store" && storeId) || (type === "company" && companyId)) {
        this.getFormData(storeId || companyId);
      }
      this.getChannelStaffList();
    },
    // 获取渠道公司详情
    async getChannelCompanyDetail(companyId) {
      const res = await getChannelCompanyDetail({
        id: companyId
      });
      if (res) {
        this.companyInfo = res;
      }
    },
    // 获取渠道公司 或者 渠道公司部门 详情 填充表单
    async getFormData(id) {
      const res = await getChannelCompanyDetail({ id });
      if (res) {
        // 处理表单编辑数据
        for (const key in this.formData) {
          this.formData[key] = res[key];
        }
        this.formData.userList = res.userList.map(item => item.userId);
        this.formData.mainBusiness =
          res.mainBusiness?.split("、").filter(Boolean) || [];
        this.formData.concatList = res.concatList.map(item => {
          item.phone = [item.phone, ...item.morePhoneList].join("，");
          return item;
        });
        if (res.address) {
          this.addressModel = `[${res.city}-${res.region}]${res.address}`;
        }
      }
    },
    // 校验公司名是否存在
    async validateCompanyFullName(rule, value, callback) {
      if (!this.formData.fullName) return;
      const res = await validateCompanyFullName({
        id: this.formData.id,
        name: this.formData.fullName
      });
      if (res && !res.result) {
        callback(new Error("该公司名称已存在，请勿重复创建！"));
      } else {
        callback();
      }
    },
    // 校验公司简称/部门名称是否存在
    async validateCompanyName(rule, value, callback) {
      if (!this.formData.name) return;
      const res = await validateCompanyName({
        id: this.formData.id,
        name: this.formData.name,
        mechanismId: this.formData.mechanismId,
        companyType: this.type === "store" ? 1 : 0
      });
      if (res && !res.result) {
        callback(new Error("该简称已存在，请勿重复创建！"));
      } else {
        callback();
      }
    },
    // 获取渠道人员
    async getChannelStaffList() {
      const res = await getChannelStaffList({
        slaveTypes: 11
      });
      if (res) {
        this.staffList = res;
      }
    },
    // 重置表单
    resetForm() {
      this.addressModel = "";
      this.formData = this.$f.deepClone(formData);
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    // 提交表单
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const params = this.handleSubmitData();
        if (!params) return;
        const res = await editChannelCompany(params);
        if (res) {
          this.$showSuccess("提交成功");
          this.$emit("success");
          this.resetForm();
        }
      });
    },
    // 处理提交数据
    handleSubmitData() {
      const params = this.$f.deepClone(this.formData);
      let valid = true;
      params.concatList = params.concatList
        .filter(item => item.name.trim() && item.phone.trim())
        .map(item => {
          const phones = item.phone.split(/,|，|、/);
          const invalidPhone = phones.find(p => !/^1\d{10}$/.test(p));
          if (invalidPhone) {
            this.$showError(`${item.name} 存在无效的手机号码 ${invalidPhone}`);
            valid = false;
            return;
          } else if (phones.length > 5) {
            this.$showError(`一个联系人最多可添加五个电话`);
            valid = false;
            return;
          }
          item.phone = phones[0];
          item.morePhoneList = phones.slice(1);
          return item;
        });
      params.mainBusiness = params.mainBusiness?.join("、") || "";
      return valid ? params : null;
    },
    // 添加联系人
    addContact() {
      this.formData.concatList.push(contactItem());
    },
    // 移除联系人
    removeContact(index) {
      this.formData.concatList.splice(index, 1);
    },
    // 选择地址
    handleAddressSelect(value) {
      const { location, cityname, adname, address } = this.addressList.find(
        item => item.id === value
      );
      const staticMap = `https://restapi.amap.com/v3/staticmap?${location}&zoom=16&size=750*750&markers=large,,A:${location}&key=${this.$f.AMAP_KEY}`;
      Object.assign(this.formData, {
        location,
        city: cityname,
        region: adname,
        address,
        // 用后端代理接口直接处理,理论上相同地址也只会请求一次高德接口
        addressImg: `https://vip.fangyibao.cn/vip2/api/v1/fxb/httpProxy/requestProxy?url=${encodeURIComponent(
          staticMap
        )}`
      });
    },
    // 地址搜索
    async addressRemoteMethod(keyword) {
      if (!keyword.trim()) return;
      const { cityName } = this.$platformInfo;
      const url = `https://restapi.amap.com/v3/place/text?city=${cityName ||
        " "}&citylimit=&keywords=${keyword}&types=&output=json&offset=20&page=1&key=${
        this.$f.AMAP_KEY
      }&extensions=all`;
      this.addressLoading = true;
      // 使用后端代理去请求接口 后端会缓存相同参数的数据 节约高德请求次数
      const res = await requestProxy(url);
      this.addressLoading = false;
      if (res.info === "OK" && res.pois) {
        this.addressList = res.pois.map(item => {
          item.label = `[${item.cityname}-${item.adname}]${item.address}`;
          return item;
        });
      } else {
        this.addressList = [];
      }
    },
    // 公司搜索
    async companyRemoteMethod(keyword) {
      if (!keyword?.trim()) return;
      const res = await getMechanismList({
        targetType: 0,
        pageSize: 100,
        pageNum: 1,
        keyword: keyword
      });
      this.companyList = res?.list || [];
    }
  }
};
</script>
<style lang="scss">
.channel-company-address-option {
  height: auto;
  padding: 10px;
  .address-item {
    display: flex;
    align-items: center;
    .cont {
      padding-left: 10px;
      flex: 1;
      line-height: 1.5;
      p {
        font-size: 15px;
      }
      span {
        font-size: 12px;
        color: #999;
      }
      p,
      span {
        display: block;
        max-width: 400px;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.tips {
  margin-top: 8px;
  color: #999;
}
.contact-list {
  li {
    display: flex;
    align-items: center;
    & + li {
      margin-top: 8px;
    }
    .name {
      max-width: 100px;
    }
    .position {
      max-width: 100px;
      margin-left: 10px;
    }
    .phone {
      margin-left: 10px;
      flex: 1;
    }
    .remove-btn {
      padding-left: 10px;
      i {
        font-size: 20px;
        color: $red;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.channel-company-address-select {
  ::v-deep {
    .el-select__input {
      margin-left: 30px;
    }
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 12px;
    .el-form-item__error {
      position: relative;
    }
  }
  .el-select {
    width: 100%;
  }
  .el-radio-group {
    .el-radio-button__inner {
      width: 115px;
    }
  }
}
</style>
