var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "搜索渠道名称、经纪人姓名"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-select', {
    staticClass: "ml8",
    attrs: {
      "placeholder": "来源/类型",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.handleSourceChange
    },
    model: {
      value: _vm.queryLabelData.source,
      callback: function callback($$v) {
        _vm.$set(_vm.queryLabelData, "source", $$v);
      },
      expression: "queryLabelData.source"
    }
  }, _vm._l(_vm.sourceOptions, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1), _c('el-select', {
    staticClass: "ml8",
    attrs: {
      "placeholder": "合作状态",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "status", $$v);
      },
      expression: "queryData.status"
    }
  }, _vm._l(_vm.statusOptions, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1), _c('el-date-picker', {
    staticStyle: {
      "width": "240px",
      "margin": "0 8px"
    },
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.handleDateChange
    },
    model: {
      value: _vm.queryLabelData.queryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.queryLabelData, "queryDate", $$v);
      },
      expression: "queryLabelData.queryDate"
    }
  })], 1), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.list
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "姓名",
      "prop": "name",
      "width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "联系电话",
      "prop": "phone"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.phone ? [_vm._v(_vm._s(_vm._f("formatPhone")(row.phone)))] : [_vm._v("-")]];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "来源"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(row.companyType == 3 ? "PCN渠道" : "自有渠道") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(row.companyType == 1 ? "渠道经纪" : "独立经纪") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "所属渠道",
      "width": "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(_vm.nameStr(row)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "注册时间",
      "prop": "inDate",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(row.inDate, 7)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "报备",
      "prop": "totalReportCount",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "带看",
      "prop": "totalTakeCount",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [row.status == 1 ? _c('span', {
          staticClass: "green"
        }, [_vm._v("合作中")]) : _vm._e(), row.status == 2 ? _c('span', {
          staticClass: "orange"
        }, [_vm._v("已暂停")]) : _vm._e()];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }