<template>
  <div>
    <el-drawer
      v-bind="$attrs"
      v-on="$listeners"
      :title="title"
      size="375px"
      top="50px"
      @open="handleOpen"
    >
      <div class="f-main">
        <div class="title">基本信息</div>
        <div class="content">
          <template v-if="type == 'store'">
            <div>
              <div>门店分部</div>
              <div>{{ detailForm.name || "-" }}</div>
            </div>
            <div>
              <div>所属公司</div>
              <div>{{ detailForm.belongToMechanismFullName || "-" }}</div>
            </div>
          </template>
          <template v-if="type == 'company'">
            <div>
              <div>公司全称</div>
              <div>{{ detailForm.fullName || "-" }}</div>
            </div>
            <div>
              <div>简称</div>
              <div>{{ detailForm.name || "-" }}</div>
            </div>
          </template>
          <div>
            <div>渠道人员</div>
            <div v-if="detailForm.userList?.length">
              <span
                class="user-list"
                v-for="(item, index) in detailForm.userList"
                :key="index"
                >{{ item.name }}</span
              >
            </div>
            <div v-else>-</div>
          </div>
          <div v-if="type == 'company'">
            <div>渠道来源</div>
            <div>
              {{ detailForm.companyType === 0 ? "自有渠道" : "PCN渠道" }}
            </div>
          </div>
          <div>
            <div>合作状态</div>
            <div :class="statusList[detailForm.status].color">
              {{ statusList[detailForm.status].name || "-" }}
            </div>
          </div>
          <div v-if="type == 'company'">
            <div>门店规模</div>
            <div>{{ detailForm.storeNumber || "-" }}</div>
          </div>
          <div>
            <div>人员规模</div>
            <div>{{ detailForm.userNumber || "-" }}</div>
          </div>
          <div>
            <div>主营业务</div>
            <div>{{ detailForm.mainBusiness || "-" }}</div>
          </div>
          <div>
            <div>行政区划</div>
            <div>
              {{ detailForm.city }}
              <span
                v-if="
                  (detailForm.city && detailForm.region) ||
                    (!detailForm.city && !detailForm.region)
                "
                >-</span
              >
              {{ detailForm.region }}
            </div>
          </div>
          <div class="mb12">
            <div>详细地址</div>
            <div>{{ detailForm.address || "-" }}</div>
          </div>
          <el-image
            v-if="detailForm.addressImg"
            class="address-img"
            :src="detailForm.addressImg"
            fit="cover"
            :preview-src-list="[detailForm.addressImg]"
          >
          </el-image>
          <template v-if="detailForm.inDate">
            <div
              class="date-info"
              v-if="detailForm.companyType === 0 || type == 'store'"
            >
              由 {{ detailForm.inUserAuthName || detailForm.inUserName }} 于
              {{ detailForm.inDate | formatDate(9) }} 创建
            </div>
            <div class="date-info" v-else>
              <span
                v-if="detailForm.inUserAuthName || detailForm.inviterUserName"
                class="mr8"
              >
                由
                {{ detailForm.inUserAuthName || detailForm.inviterUserName }}
                邀请
              </span>
              于 {{ detailForm.inDate | formatDate(9) }} 加入
            </div>
          </template>
        </div>
        <template v-if="detailForm.concatList.length">
          <div class="title">联系人</div>
          <div class="contact-box">
            <div
              v-for="(item, index) in detailForm.concatList"
              :key="index"
              class="contact-list"
            >
              <div>
                {{
                  item.name?.substring(item.name.length - 2, item.name.length)
                }}
              </div>
              <div>
                <div>{{ item.name }}</div>
                <div>
                  <span v-if="item.position">{{ item.position }}</span
                  >&nbsp;
                  {{ item.phone | formatPhone }}
                  <span
                    v-for="(p, i) in item.morePhoneList"
                    :key="i"
                    class="more-phone"
                    >{{ p | formatPhone }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="detailForm.note">
          <div class="title mt15">
            {{ type == "store" ? "备注信息" : "备注描述" }}
          </div>
          <div class="content white-wrap">{{ detailForm.note }}</div>
        </template>
      </div>
      <div class="handle-btn" v-if="detailForm.id">
        <div
          v-if="type == 'company' && companyType === 0"
          @click="
            cType = 'store';
            companyEditVisible = true;
          "
        >
          <span>添加部门/分部</span>
        </div>
        <div
          v-if="companyType != 2"
          @click="
            cType = type;
            companyEditVisible = true;
          "
        >
          <span>编辑</span>
        </div>
        <div v-if="companyType != 2" class="red" @click="handleDelete">
          <span>删除</span>
        </div>
        <template v-if="type == 'company' && companyType === 2">
          <div @click="handleStatus">
            <span>{{ detailForm.status == 1 ? "暂停合作" : "恢复合作" }}</span>
          </div>
          <div class="red" @click="handleDelete"><span>移除渠道</span></div>
        </template>
      </div>
    </el-drawer>
    <!--    新增、编辑-->
    <c-company-edit
      :visible.sync="companyEditVisible"
      :type="cType"
      :companyId="
        type === 'store' ? detailForm.belongToMechanismId : detailForm.id
      "
      :storeId="type === 'store' ? detailForm.id : null"
      @success="editSuccess"
    ></c-company-edit>
  </div>
</template>

<script>
import {
  getChannelCompanyDetail,
  mechanismBatchDelete,
  mechanismBatchStatus
} from "@/api/channel";
import CCompanyEdit from "./edit";
export default {
  components: { CCompanyEdit },
  props: {
    title: {
      type: String
    },
    statusList: {
      type: Array,
      default: () => []
    }, //公司状态
    detailId: {
      type: [Number, String]
    }, //详情id
    type: {
      type: [Number, String]
    }, //类型：company公司，store门店
    companyType: {
      type: Number
    } //渠道类型：0自有，2PCN
  },
  data() {
    return {
      companyEditVisible: false,
      detailForm: { status: 1, concatList: [], userList: [] },
      cType: "" //类型：company公司，store门店
    };
  },
  watch: {
    //类型公司或门店
    type: {
      immediate: true,
      deep: true,
      handler(val) {
        this.cType = val || "company";
      }
    }
  },
  methods: {
    //弹框打开
    async handleOpen() {
      const res = await getChannelCompanyDetail({ id: this.detailId });
      if (res) {
        this.detailForm = res;
      }
    },
    //关闭
    close() {
      this.$emit("update:visible", false);
    },
    //删除渠道公司和门店
    handleDelete() {
      this.$showConfirm(async () => {
        const res = await mechanismBatchDelete({
          idList: [this.detailId]
        });
        if (res) {
          this.$message({
            type: "success",
            message: (this.companyType === 0 ? "删除" : "移除") + "成功"
          });
          this.$emit("success");
          this.close();
        }
      }, `此操作将删除所选${this.type == "company" ? "渠道" : "门店/分部"}且无法恢复，是否继续？`);
    },
    //暂停合作/恢复合作
    async handleStatus() {
      const res = await mechanismBatchStatus({
        idList: [this.detailId],
        status: this.detailForm.status == 1 ? 2 : 1
      });
      if (res) {
        this.$message({
          type: "success",
          message: "更新成功"
        });
        this.$emit("success");
        this.handleOpen();
      }
    },
    //编辑成功
    editSuccess() {
      this.handleOpen();
      this.$emit("success");
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-drawer__header {
    margin-bottom: 22px;
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
  .el-drawer__body {
    background: #f2f2f2;
    padding: 12px 0 70px;
    flex: 1;
    overflow-y: auto;
  }
}
.f-main {
  padding: 0 20px;
  width: 375px;
  .title {
    color: #aaa;
    margin-bottom: 10px;
  }
  .content {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    word-break: break-all;
    & > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
      font-size: 15px;
      color: #aaa;
      & > div:first-child {
        margin-right: 15px;
        min-width: 60px;
      }
      & > div:last-child {
        flex: 1;
        color: #333;
      }
      &.mb12 {
        margin-bottom: 12px;
      }
      .user-list:not(:last-child)::after {
        content: ",";
      }
    }
    .address-img {
      width: 100%;
      height: 90px;
      background: #ccc;
      margin-bottom: 12px;
      border-radius: 4px;
    }
    .date-info {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
  .contact-box {
    background: #fff;
    padding: 17px;
    border-radius: 7px;
    .contact-list {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      & > div:first-child {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: red;
        margin-right: 10px;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > div:last-child {
        flex: 1;
        div:first-child {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 2px;
        }
        div:last-child {
          color: #aaa;
          font-size: 13px;
          .more-phone::before {
            content: "/";
          }
        }
      }
    }
  }
  .white-wrap {
    white-space: pre-wrap;
    line-height: 22px;
  }
}
.handle-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #2f6cfd;
  font-size: 15px;
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: rgba(228, 228, 228, 0.35) 0px 0px 10px;
  & > div {
    width: 180px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    span {
      flex: 1;
      text-align: center;
    }
    &.red {
      color: red;
    }
    &:not(:last-child)::after {
      content: "|";
      color: #ccc;
    }
  }
}
</style>
