<template>
  <div class="content">
    <div class="filter">
      <el-input
        placeholder="搜索渠道名称、经纪人姓名"
        v-model="queryData.keyword"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
      <el-select
        v-model="queryLabelData.source"
        placeholder="来源/类型"
        class="ml8"
        filterable
        clearable
        @change="handleSourceChange"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in sourceOptions"
          :key="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="queryData.status"
        placeholder="合作状态"
        class="ml8"
        filterable
        clearable
        @change="search"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in statusOptions"
          :key="item.id"
        >
        </el-option>
      </el-select>
      <el-date-picker
        style="width: 240px;margin:0 8px;"
        v-model="queryLabelData.queryDate"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="handleDateChange"
      >
      </el-date-picker>
    </div>
    <el-table border class="mt20" :data="list" @sort-change="sortChange">
      <el-table-column label="姓名" prop="name" width="120"></el-table-column>
      <el-table-column label="联系电话" prop="phone">
        <template v-slot="{ row }">
          <template v-if="row.phone">{{ row.phone | formatPhone }}</template>
          <template v-else>-</template>
        </template>
      </el-table-column>
      <el-table-column label="来源">
        <template v-slot="{ row }">
          {{ row.companyType == 3 ? "PCN渠道" : "自有渠道" }}
        </template>
      </el-table-column>
      <el-table-column label="类型">
        <template v-slot="{ row }">
          {{ row.companyType == 1 ? "渠道经纪" : "独立经纪" }}
        </template>
      </el-table-column>
      <el-table-column label="所属渠道" width="280">
        <template v-slot="{ row }">
          {{ nameStr(row) }}
        </template>
      </el-table-column>
      <el-table-column label="注册时间" prop="inDate" sortable="custom">
        <template v-slot="{ row }">
          {{ row.inDate | formatDate(7) }}
        </template>
      </el-table-column>
      <el-table-column label="报备" prop="totalReportCount" sortable="custom">
      </el-table-column>
      <el-table-column label="带看" prop="totalTakeCount" sortable="custom">
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="{ row }">
          <span class="green" v-if="row.status == 1">合作中</span>
          <span class="orange" v-if="row.status == 2">已暂停</span>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
  </div>
</template>

<script>
import { getMechanismAgent } from "@/api/channel.js";
export default {
  data() {
    return {
      statusOptions: [
        {
          id: 1,
          name: "合作中"
        },
        {
          id: 2,
          name: "已暂停"
        }
      ],
      sourceOptions: [
        {
          id: "0-targetType",
          name: "自有渠道"
        },
        {
          id: "2-targetType",
          name: "PCN渠道"
        },
        {
          id: "1-companyType",
          name: "渠道经纪"
        },
        {
          id: "5-companyType",
          name: "独立经纪"
        }
      ],
      queryLabelData: {
        queryDate: "",
        source: ""
      },
      queryData: {
        status: null, // 1 合作中, 2暂停
        targetType: null, // 0 为自有渠道 2为PCN渠道
        companyType: null, // 1 渠道经纪 5 独立经纪
        keyword: "",
        inDateStart: "",
        inDateEnd: "",
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      list: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    handleDateChange(date) {
      const [inDateStart, inDateEnd] = date || [];
      Object.assign(this.queryData, {
        inDateStart,
        inDateEnd
      });
      this.search();
    },
    handleSourceChange(val) {
      let [value, key] = val.split("-");
      this.queryData.targetType = null;
      this.queryData.companyType = null;
      this.queryData[key] = Number(value);
      this.search();
    },
    sortChange({ prop, order }) {
      // 1 加入时间 2 近30天报备 3 近30天带看 4 经纪人数量 5 总报备 6 总带看
      let obj = {
        inDate: 1,
        totalReportCount: 5,
        totalTakeCount: 6
      };
      // 1 降序 0升序
      let orderObj = {
        ascending: 0,
        descending: 1
      };
      this.queryData.sort = order ? obj[prop] : null;
      this.queryData.sortType = orderObj[order];
      this.getList();
    },
    nameStr(row) {
      let result = "";
      const { mechanismBranchName, mechanismName, companyType } = row;
      if (companyType == 2 || companyType == 3) {
        result = "独立经纪";
      } else {
        result = [mechanismBranchName, mechanismName]
          .filter(item => item)
          .join("-");
      }
      return result;
    },
    async getList() {
      const res = await getMechanismAgent(this.queryData);
      if (res) {
        const { total, list } = res;
        this.total = total;
        this.list = list;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
