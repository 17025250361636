var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "follow-item"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('el-image', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.item.inUserLog,
      "mode": "aspectFill"
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.inUserName))]), _c('div', {
    staticClass: "desc"
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.item.inDate, 7))), _c('span', {
    staticClass: "dot"
  }, [_vm._v("·")]), _vm.item.joinUserInfo && _vm.item.joinUserInfo.length ? [_c('span', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.joinUserLabel))]), _vm.item.joinUserInfo.length > 2 ? _c('el-popover', {
    attrs: {
      "placement": "bottom-start",
      "width": "375",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "user-list"
  }, _vm._l(_vm.item.joinUserInfo, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('el-image', {
      staticClass: "avatar",
      attrs: {
        "src": item.log,
        "mode": "aspectFill"
      }
    }), _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), item.type ? _c('div', {
      staticClass: "p"
    }, [_vm._v(_vm._s(item.type))]) : _vm._e()])], 1);
  }), 0), _c('span', {
    staticClass: "link",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._v("等" + _vm._s(_vm.item.joinUserInfo.length) + "人参与")])]) : _c('span', [_vm._v("参与")])] : [_vm._v("发布了跟进")]], 2)])], 1), _c('div', {
    staticClass: "tags"
  }, [_c('div', {
    staticClass: "tag"
  }, [_vm._v(_vm._s(_vm.typeMaps[_vm.item.type]))]), _vm.formatDate ? _c('div', {
    staticClass: "tag"
  }, [_vm._v(_vm._s(_vm.formatDate))]) : _vm._e()]), _vm.item.content ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "txt-wrap",
    style: {
      maxHeight: _vm.textFull || !_vm.hideText ? 'none' : '160px'
    }
  }, [_c('div', {
    ref: "txt",
    staticClass: "txt",
    domProps: {
      "innerHTML": _vm._s(_vm.item.content.replace(/(\r|\n)+/g, '<br>'))
    }
  })]), _vm.txtHeight > 160 ? _c('div', {
    staticClass: "full-btn",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.textFull = !_vm.textFull;
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.textFull ? "收起" : "展开"))])]) : _vm._e()]) : _vm._e(), _vm.item.images && _vm.item.images.length ? _c('div', {
    staticClass: "pic-list"
  }, _vm._l(_vm.item.images, function (url, img_index) {
    return _c('el-image', {
      key: img_index,
      class: {
        single: _vm.item.images.length == 1
      },
      attrs: {
        "src": url,
        "mode": "aspectFill",
        "preview-src-list": _vm.item.images
      }
    });
  }), 1) : _vm._e(), _vm.item.channelIds && _vm.item.channelIds.length ? _c('div', {
    staticClass: "channel-info"
  }, [_c('i', {
    staticClass: "iconfont colo3 fs16"
  }, [_vm._v("")]), _c('div', {
    staticClass: "desc"
  }, _vm._l(_vm.item.channelIds, function (channel, index) {
    return _c('span', {
      key: index
    }, [_c('span', [_vm._v(_vm._s(channel.name))]), channel.type != '不限' ? _c('span', {
      staticClass: "sub"
    }, [_vm._v("(" + _vm._s(channel.type) + ")")]) : _vm._e(), index != _vm.item.channelIds.length - 1 ? _c('span', {
      staticClass: "dot"
    }, [_vm._v("、")]) : _vm._e()]);
  }), 0), _c('el-popover', {
    attrs: {
      "placement": "top-start",
      "width": "375",
      "trigger": "hover"
    }
  }, [_c('div', {
    staticClass: "more-channel"
  }, [_c('div', {
    staticClass: "channel-list"
  }, _vm._l(_vm.item.channelIds, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "p"
    }, [_vm._v(_vm._s(item.type))])]);
  }), 0)]), _c('span', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._v(" 共" + _vm._s(_vm.item.channelIds.length) + "家渠道 ")])])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }