<template>
  <div class="content">
    <div class="filter">
      <el-input
        placeholder="搜索渠道公司、独立经纪"
        v-model="queryData.keyword"
        style="width: 320px;"
        class="f-search-bar"
      >
        <el-button slot="append" @click="search">搜索</el-button>
      </el-input>
    </div>
    <el-table border class="mt20" :data="list" @sort-change="handleSortChange">
      <el-table-column label="渠道名称" prop="name"></el-table-column>
      <el-table-column label="渠道类型">
        <template v-slot="{ row }">
          {{ companyTypeLabelMap[row.companyType] }}
        </template>
      </el-table-column>
      <el-table-column label="联系人" prop="concatName"> </el-table-column>
      <el-table-column label="联系电话" prop="concatPhone">
        <template v-slot="{ row }">
          {{ row.concatPhone | formatPhone }}
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="inDate">
        <template v-slot="{ row }">
          {{ row.inDate | formatDate(0) }}
        </template>
      </el-table-column>
      <el-table-column label="经纪人" sortable prop="userCount">
        <template v-slot="{ row }">
          {{ row.userCount ? row.userCount + "人" : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template v-slot="{ row }">
          <el-link type="primary" @click="changeChannelStatus(row)"
            >通过</el-link
          >
          <span style="padding: 0 10px;color: #aaa;">|</span>
          <el-link type="danger" @click="removeChannel(row)">移除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
  </div>
</template>

<script>
import {
  getPcnChannelCompanyList,
  removePcnChannelCompany,
  changePcnChannelCompanyStatus
} from "@/api/channel";
export default {
  data() {
    return {
      queryData: {
        pageNum: 1,
        keyword: "",
        orderByKey: null,
        orderByType: null,
        pageSize: 10
      },
      total: 0,
      list: [],
      companyTypeLabelMap: {
        0: "自有渠道",
        2: "PCN渠道",
        3: "独立经纪"
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      const res = await getPcnChannelCompanyList(this.queryData);
      if (res) {
        this.total = res.total;
        this.list = res.list;
      }
    },
    // 排序查询
    handleSortChange({ prop, order }) {
      const propMap = {
        userCount: 4
      };
      const orderMap = {
        descending: "DESC",
        ascending: "ASC"
      };
      this.queryData.orderByKey = order ? propMap[prop] : null;
      this.queryData.orderByType = orderMap[order];
      this.getList();
    },
    // 通过
    async changeChannelStatus(row) {
      const res = await changePcnChannelCompanyStatus({
        memberId: row.memberId,
        status: 1
      });
      if (res) this.getList();
    },
    // 移除
    async removeChannel(row) {
      const res = await removePcnChannelCompany({
        companyIdList: [row.companyId]
      });
      if (res) this.getList();
    }
  }
};
</script>

<style scoped lang="scss"></style>
