var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "filter"
  }, [_c('el-input', {
    staticClass: "f-search-bar",
    staticStyle: {
      "width": "320px"
    },
    attrs: {
      "placeholder": "搜索渠道公司、独立经纪"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1)], 1), _c('el-table', {
    staticClass: "mt20",
    attrs: {
      "border": "",
      "data": _vm.list
    },
    on: {
      "sort-change": _vm.handleSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "渠道名称",
      "prop": "name"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道类型"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(" " + _vm._s(_vm.companyTypeLabelMap[row.companyType]) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "联系人",
      "prop": "concatName"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "联系电话",
      "prop": "concatPhone"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(" " + _vm._s(_vm._f("formatPhone")(row.concatPhone)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "申请时间",
      "prop": "inDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(row.inDate, 0)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "经纪人",
      "sortable": "",
      "prop": "userCount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(" " + _vm._s(row.userCount ? row.userCount + "人" : "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "150px",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.changeChannelStatus(row);
            }
          }
        }, [_vm._v("通过")]), _c('span', {
          staticStyle: {
            "padding": "0 10px",
            "color": "#aaa"
          }
        }, [_vm._v("|")]), _c('el-link', {
          attrs: {
            "type": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.removeChannel(row);
            }
          }
        }, [_vm._v("移除")])];
      }
    }])
  })], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }