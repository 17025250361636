var render = function render(){
  var _vm$detailForm$userLi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-drawer', _vm._g(_vm._b({
    attrs: {
      "title": _vm.title,
      "size": "375px",
      "top": "50px"
    },
    on: {
      "open": _vm.handleOpen
    }
  }, 'el-drawer', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "f-main"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("基本信息")]), _c('div', {
    staticClass: "content"
  }, [_vm.type == 'store' ? [_c('div', [_c('div', [_vm._v("门店分部")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.name || "-"))])]), _c('div', [_c('div', [_vm._v("所属公司")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.belongToMechanismFullName || "-"))])])] : _vm._e(), _vm.type == 'company' ? [_c('div', [_c('div', [_vm._v("公司全称")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.fullName || "-"))])]), _c('div', [_c('div', [_vm._v("简称")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.name || "-"))])])] : _vm._e(), _c('div', [_c('div', [_vm._v("渠道人员")]), (_vm$detailForm$userLi = _vm.detailForm.userList) !== null && _vm$detailForm$userLi !== void 0 && _vm$detailForm$userLi.length ? _c('div', _vm._l(_vm.detailForm.userList, function (item, index) {
    return _c('span', {
      key: index,
      staticClass: "user-list"
    }, [_vm._v(_vm._s(item.name))]);
  }), 0) : _c('div', [_vm._v("-")])]), _vm.type == 'company' ? _c('div', [_c('div', [_vm._v("渠道来源")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailForm.companyType === 0 ? "自有渠道" : "PCN渠道") + " ")])]) : _vm._e(), _c('div', [_c('div', [_vm._v("合作状态")]), _c('div', {
    class: _vm.statusList[_vm.detailForm.status].color
  }, [_vm._v(" " + _vm._s(_vm.statusList[_vm.detailForm.status].name || "-") + " ")])]), _vm.type == 'company' ? _c('div', [_c('div', [_vm._v("门店规模")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.storeNumber || "-"))])]) : _vm._e(), _c('div', [_c('div', [_vm._v("人员规模")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.userNumber || "-"))])]), _c('div', [_c('div', [_vm._v("主营业务")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.mainBusiness || "-"))])]), _c('div', [_c('div', [_vm._v("行政区划")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailForm.city) + " "), _vm.detailForm.city && _vm.detailForm.region || !_vm.detailForm.city && !_vm.detailForm.region ? _c('span', [_vm._v("-")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.detailForm.region) + " ")])]), _c('div', {
    staticClass: "mb12"
  }, [_c('div', [_vm._v("详细地址")]), _c('div', [_vm._v(_vm._s(_vm.detailForm.address || "-"))])]), _vm.detailForm.addressImg ? _c('el-image', {
    staticClass: "address-img",
    attrs: {
      "src": _vm.detailForm.addressImg,
      "fit": "cover",
      "preview-src-list": [_vm.detailForm.addressImg]
    }
  }) : _vm._e(), _vm.detailForm.inDate ? [_vm.detailForm.companyType === 0 || _vm.type == 'store' ? _c('div', {
    staticClass: "date-info"
  }, [_vm._v(" 由 " + _vm._s(_vm.detailForm.inUserAuthName || _vm.detailForm.inUserName) + " 于 " + _vm._s(_vm._f("formatDate")(_vm.detailForm.inDate, 9)) + " 创建 ")]) : _c('div', {
    staticClass: "date-info"
  }, [_vm.detailForm.inUserAuthName || _vm.detailForm.inviterUserName ? _c('span', {
    staticClass: "mr8"
  }, [_vm._v(" 由 " + _vm._s(_vm.detailForm.inUserAuthName || _vm.detailForm.inviterUserName) + " 邀请 ")]) : _vm._e(), _vm._v(" 于 " + _vm._s(_vm._f("formatDate")(_vm.detailForm.inDate, 9)) + " 加入 ")])] : _vm._e()], 2), _vm.detailForm.concatList.length ? [_c('div', {
    staticClass: "title"
  }, [_vm._v("联系人")]), _c('div', {
    staticClass: "contact-box"
  }, _vm._l(_vm.detailForm.concatList, function (item, index) {
    var _item$name;
    return _c('div', {
      key: index,
      staticClass: "contact-list"
    }, [_c('div', [_vm._v(" " + _vm._s((_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.substring(item.name.length - 2, item.name.length)) + " ")]), _c('div', [_c('div', [_vm._v(_vm._s(item.name))]), _c('div', [item.position ? _c('span', [_vm._v(_vm._s(item.position))]) : _vm._e(), _vm._v("  " + _vm._s(_vm._f("formatPhone")(item.phone)) + " "), _vm._l(item.morePhoneList, function (p, i) {
      return _c('span', {
        key: i,
        staticClass: "more-phone"
      }, [_vm._v(_vm._s(_vm._f("formatPhone")(p)))]);
    })], 2)])]);
  }), 0)] : _vm._e(), _vm.detailForm.note ? [_c('div', {
    staticClass: "title mt15"
  }, [_vm._v(" " + _vm._s(_vm.type == "store" ? "备注信息" : "备注描述") + " ")]), _c('div', {
    staticClass: "content white-wrap"
  }, [_vm._v(_vm._s(_vm.detailForm.note))])] : _vm._e()], 2), _vm.detailForm.id ? _c('div', {
    staticClass: "handle-btn"
  }, [_vm.type == 'company' && _vm.companyType === 0 ? _c('div', {
    on: {
      "click": function click($event) {
        _vm.cType = 'store';
        _vm.companyEditVisible = true;
      }
    }
  }, [_c('span', [_vm._v("添加部门/分部")])]) : _vm._e(), _vm.companyType != 2 ? _c('div', {
    on: {
      "click": function click($event) {
        _vm.cType = _vm.type;
        _vm.companyEditVisible = true;
      }
    }
  }, [_c('span', [_vm._v("编辑")])]) : _vm._e(), _vm.companyType != 2 ? _c('div', {
    staticClass: "red",
    on: {
      "click": _vm.handleDelete
    }
  }, [_c('span', [_vm._v("删除")])]) : _vm._e(), _vm.type == 'company' && _vm.companyType === 2 ? [_c('div', {
    on: {
      "click": _vm.handleStatus
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.detailForm.status == 1 ? "暂停合作" : "恢复合作"))])]), _c('div', {
    staticClass: "red",
    on: {
      "click": _vm.handleDelete
    }
  }, [_c('span', [_vm._v("移除渠道")])])] : _vm._e()], 2) : _vm._e()]), _c('c-company-edit', {
    attrs: {
      "visible": _vm.companyEditVisible,
      "type": _vm.cType,
      "companyId": _vm.type === 'store' ? _vm.detailForm.belongToMechanismId : _vm.detailForm.id,
      "storeId": _vm.type === 'store' ? _vm.detailForm.id : null
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.companyEditVisible = $event;
      },
      "success": _vm.editSuccess
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }