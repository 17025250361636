<template>
  <div class="follow-item">
    <div class="title">
      <el-image :src="item.inUserLog" mode="aspectFill" class="avatar">
      </el-image>
      <div class="info">
        <div class="name">{{ item.inUserName }}</div>
        <div class="desc">
          {{ item.inDate | formatDate(7) }}<span class="dot">·</span>
          <template v-if="item.joinUserInfo && item.joinUserInfo.length">
            <span class="label">{{ joinUserLabel }}</span>
            <el-popover
              placement="bottom-start"
              width="375"
              trigger="hover"
              v-if="item.joinUserInfo.length > 2"
            >
              <div class="user-list">
                <div
                  class="item"
                  v-for="(item, index) in item.joinUserInfo"
                  :key="index"
                >
                  <el-image
                    :src="item.log"
                    mode="aspectFill"
                    class="avatar"
                  ></el-image>
                  <div class="info">
                    <div class="name">{{ item.name }}</div>
                    <div class="p" v-if="item.type">{{ item.type }}</div>
                  </div>
                </div>
              </div>
              <span class="link" slot="reference"
                >等{{ item.joinUserInfo.length }}人参与</span
              >
            </el-popover>
            <span v-else>参与</span>
          </template>
          <template v-else>发布了跟进</template>
        </div>
      </div>
    </div>
    <div class="tags">
      <div class="tag">{{ typeMaps[item.type] }}</div>
      <div class="tag" v-if="formatDate">{{ formatDate }}</div>
    </div>
    <div class="content" v-if="item.content">
      <div
        class="txt-wrap"
        :style="{ maxHeight: textFull || !hideText ? 'none' : '160px' }"
      >
        <div
          class="txt"
          ref="txt"
          v-html="item.content.replace(/(\r|\n)+/g, '<br>')"
        ></div>
      </div>
      <div
        class="full-btn"
        v-if="txtHeight > 160"
        @click.stop="textFull = !textFull"
      >
        <span>{{ textFull ? "收起" : "展开" }}</span>
      </div>
    </div>
    <div class="pic-list" v-if="item.images && item.images.length">
      <el-image
        v-for="(url, img_index) in item.images"
        :src="url"
        :class="{ single: item.images.length == 1 }"
        mode="aspectFill"
        :key="img_index"
        :preview-src-list="item.images"
      ></el-image>
    </div>
    <div class="channel-info" v-if="item.channelIds && item.channelIds.length">
      <i class="iconfont colo3 fs16">&#xe6b7;</i>
      <div class="desc">
        <span v-for="(channel, index) in item.channelIds" :key="index"
          ><span>{{ channel.name }}</span
          ><span v-if="channel.type != '不限'" class="sub"
            >({{ channel.type }})</span
          ><span class="dot" v-if="index != item.channelIds.length - 1"
            >、</span
          ></span
        >
      </div>
      <el-popover placement="top-start" width="375" trigger="hover">
        <div class="more-channel">
          <div class="channel-list">
            <div
              class="item"
              v-for="(item, index) in item.channelIds"
              :key="index"
            >
              <div class="title">
                {{ item.name }}
              </div>
              <div class="p">{{ item.type }}</div>
            </div>
          </div>
        </div>
        <span slot="reference"> 共{{ item.channelIds.length }}家渠道 </span>
      </el-popover>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    hideText: {
      // 是否隐藏多余文字
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getTxtHeight();
    });
  },
  computed: {
    joinUserLabel() {
      return this.item.joinUserInfo
        ? this.item.joinUserInfo
            .slice(0, 2)
            .map(item => item.name)
            .join(" ")
        : "";
    },
    formatDate() {
      let result = "";
      const { operationTimeStart, operationTimeEnd, fullDay } = this.item;
      if (!operationTimeStart || !operationTimeEnd) return "";
      // 开始时间和结束时间是否是同一天
      let isSameDay = this.$time(operationTimeStart).isSame(
        operationTimeEnd,
        "day"
      );
      // 时间格式化字符串
      const formatFn = date => {
        let isCurYear = this.$time().isSame(date, "year");
        if (isCurYear) {
          // 今年
          return fullDay ? "M月D日" : "M月D日 HH:mm";
        } else {
          // 往年
          return fullDay ? "YYYY年M月D日" : "YYYY年M月D日 HH:mm";
        }
      };
      if (fullDay) {
        // 全天
        if (isSameDay) {
          // 同一天
          result = `${this.$time(operationTimeStart).format(
            formatFn(operationTimeStart)
          )} 全天`;
        } else {
          // 跨天
          result = `${this.$time(operationTimeStart).format(
            formatFn(operationTimeStart)
          )}-${this.$time(operationTimeEnd).format(
            formatFn(operationTimeEnd)
          )}`;
        }
      } else {
        if (isSameDay) {
          // 同一天
          result = `${this.$time(operationTimeStart).format(
            formatFn(operationTimeStart)
          )} - ${this.$time(operationTimeEnd).format("HH:mm")}`;
        } else {
          // 跨天
          result = `${this.$time(operationTimeStart).format(
            formatFn(operationTimeStart)
          )}-${this.$time(operationTimeEnd).format(
            formatFn(operationTimeEnd)
          )}`;
        }
      }
      return result;
    }
  },
  data() {
    return {
      textFull: false,
      txtHeight: 0,
      typeMaps: {
        1: "拜访",
        2: "培训",
        3: "日志",
        4: "其它"
      } // 1：拜访， 2：培训， 3：日志，4：其它
    };
  },
  methods: {
    getTxtHeight() {
      // 获取文本内容高度
      if (!this.$refs.txt) return;
      this.txtHeight = this.$refs.txt.clientHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.follow-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 15px 25px 15px;
  width: 470px;
  .title {
    display: flex;
    align-items: center;
    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #aaa;
    }
    .info {
      flex: 1;
    }
    .name {
      font-size: 15px;
      color: #333;
      font-weight: 400;
      line-height: 100%;
    }
    .desc {
      font-size: 12px;
      color: #aaa;
      line-height: 1;
      margin-top: 5px;
      .label {
        color: #333;
        margin-right: 5px;
      }
      .link {
        color: #556c98;
        display: inline-block;
      }
      .dot {
        margin: 0 5px;
      }
    }
  }
  .tags {
    @include flex-yc;
    margin-top: 15px;
    flex-wrap: wrap;
    .tag {
      @include flex-xyc;
      height: 30px;
      background-color: rgba(242, 242, 242, 0.8);
      border-radius: 4px;
      font-size: 13px;
      color: #333;
      font-weight: 650;
      padding: 0 15px;
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .content {
    font-size: 15px;
    line-height: 26px;
    margin-top: 10px;
    .txt-wrap {
      overflow: hidden;
    }
    .full-btn {
      width: fit-content;
      span {
        cursor: pointer;
        color: #53739c;
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
  .pic-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .el-image {
      display: block;
      width: 104px;
      height: 93px;
      border-radius: 3px;
      background: #eee;
      margin-top: 5px;
      margin-right: 5px;
      &.single {
        width: 202px;
        height: 164px;
      }
    }
  }
  .channel-info {
    @include flex-yc;
    height: 30px;
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 4px;
    padding: 0 10px;
    font-size: 13px;
    color: #7f7f7f;
    margin-top: 15px;
    .desc {
      max-width: 320px;
      margin-left: 10px;
      margin-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #333;
      .sub,
      .dot {
        color: #aaa;
      }
    }
  }
}
.user-list {
  padding: 10px;
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-row-gap: 20px;
  grid-column-gap: 8px;
  max-height: 300px;
  overflow-y: auto;
  .item {
    @include flex-yc;
    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #aaa;
    }
    .info {
      flex: 1;
      overflow: hidden;
    }
    .name {
      font-size: 14px;
      color: #333;
      height: 16px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .p {
      font-size: 12px;
      color: #aaa;
      line-height: 100%;
      margin-top: 10px;
    }
  }
}
.more-channel {
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  padding: 0 15px;
  .item {
    padding: 10px 0;
    .title {
      font-size: 15px;
      color: #333;
      line-height: 1;
    }
    .p {
      font-size: 13px;
      color: #aaa;
      line-height: 18px;
      margin-top: 8px;
    }
  }
  .item + .item {
    border-top: 1px solid #f2f2f2;
  }
}
</style>
