var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "search-bar"
  }, [_c('el-input', {
    staticClass: "input--append f-search-bar",
    attrs: {
      "placeholder": "搜索渠道公司"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  }, [_vm._v("搜索")])], 1), _c('el-select', {
    staticClass: "ml10",
    attrs: {
      "placeholder": "渠道人员",
      "filterable": "",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.channelUserList,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "channelUserList", $$v);
      },
      expression: "queryData.channelUserList"
    }
  }, _vm._l(_vm.channelUserList, function (item) {
    return _c('el-option', {
      key: item.userId,
      attrs: {
        "label": item.name,
        "value": item.userId
      }
    });
  }), 1), _c('el-select', {
    staticClass: "ml10",
    attrs: {
      "placeholder": "渠道来源",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.targetType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "targetType", $$v);
      },
      expression: "queryData.targetType"
    }
  }, _vm._l(_vm.targetTypeList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1), _c('el-select', {
    staticClass: "ml10",
    attrs: {
      "placeholder": "合作状态",
      "clearable": ""
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "status", $$v);
      },
      expression: "queryData.status"
    }
  }, _vm._l(_vm.statusOptionArr, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1), _c('el-date-picker', {
    staticClass: "input-date ml10",
    attrs: {
      "type": "daterange",
      "range-separator": "-",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "format": "yyyy-MM-dd",
      "value-format": "yyyy-MM-dd"
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryDate,
      callback: function callback($$v) {
        _vm.queryDate = $$v;
      },
      expression: "queryDate"
    }
  }), _c('el-dropdown', {
    staticClass: "ml10 mr10",
    on: {
      "command": _vm.handleCommand
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    }
  }, [_vm._v(" 创建渠道"), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    attrs: {
      "command": "company"
    }
  }, [_vm._v("渠道公司")]), _c('el-dropdown-item', {
    attrs: {
      "command": "store"
    }
  }, [_vm._v("门店/分部")])], 1)], 1), _c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.exportLoading
    },
    on: {
      "click": _vm.exportChannel
    }
  }, [_vm._v("全量导出")])], 1), _c('el-table', {
    ref: "multipleTable",
    staticClass: "mt15",
    attrs: {
      "border": "",
      "row-class-name": _vm.tableRowClassName,
      "data": _vm.tableData,
      "row-key": "id",
      "lazy": "",
      "load": _vm.loadStoreList,
      "tree-props": {
        children: 'children',
        hasChildren: 'hasChildren'
      }
    },
    on: {
      "selection-change": _vm.selectionChange,
      "sort-change": _vm.sortChange,
      "cell-click": _vm.cellClick
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "selection",
      "width": "55",
      "fixed": "",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "name",
      "label": "渠道名称"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('div', {
          staticClass: "blue pointer inline-block",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openDetail(scope.row);
            }
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道来源"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(_vm.companyTypeMaps[scope.row.companyType]) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "区域"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$row = _ref.row,
          city = _ref$row.city,
          region = _ref$row.region;
        return [_vm._v(" " + _vm._s(city)), city && region || !city && !region ? _c('span', [_vm._v("-")]) : _vm._e(), _vm._v(_vm._s(region) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "渠道人员"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        var _scope$row$userList;
        return [(_scope$row$userList = scope.row.userList) !== null && _scope$row$userList !== void 0 && _scope$row$userList.length ? _c('div', _vm._l(scope.row.userList, function (item, index) {
          return _c('span', {
            key: index,
            staticClass: "user-list"
          }, [_vm._v(_vm._s(item.name))]);
        }), 0) : _c('div', [_vm._v("-")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "创建/邀请"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _ref2$row = _ref2.row,
          companyType = _ref2$row.companyType,
          inUserAuthName = _ref2$row.inUserAuthName,
          inUserName = _ref2$row.inUserName,
          inviterUserName = _ref2$row.inviterUserName;
        return [_vm._v(" " + _vm._s(companyType === 0 ? inUserAuthName || inUserName || "-" : inUserAuthName || inviterUserName || "-") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "inDate",
      "label": "创建/加入",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var inDate = _ref3.row.inDate;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(inDate, 7)) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "userCount",
      "label": "经纪人",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "totalReportCount",
      "label": "报备",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "totalTakeCount",
      "label": "带看",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "状态"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var status = _ref4.row.status;
        return [_c('div', {
          class: _vm.statusList[status].color
        }, [_vm._v(" " + _vm._s(_vm.statusList[status].name) + " ")])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "footer"
  }, [_c('div', [_c('el-dropdown', {
    staticClass: "ml10",
    on: {
      "command": _vm.handleStatus
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    }
  }, [_vm._v(" 更新状态"), _c('i', {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.statusOptions, function (item, index) {
    return _c('el-dropdown-item', {
      key: index,
      attrs: {
        "command": item.value,
        "disabled": item.disabled
      }
    }, [_vm._v(_vm._s(item.text))]);
  }), 1)], 1), _c('el-button', {
    staticClass: "red ml10",
    on: {
      "click": _vm.handleDelete
    }
  }, [_vm._v("删除渠道")])], 1), _c('f-pagination', {
    attrs: {
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total,
      "fixed": false
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getList
    }
  })], 1), _c('detail', {
    ref: "detail",
    attrs: {
      "title": _vm.dialogTitle,
      "visible": _vm.detailDialog,
      "statusList": _vm.statusList,
      "detailId": _vm.detailId,
      "type": _vm.type,
      "companyType": _vm.companyType
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.detailDialog = $event;
      },
      "success": _vm.getList
    }
  }), _c('c-company-edit', {
    attrs: {
      "visible": _vm.companyEditVisible,
      "type": _vm.type
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.companyEditVisible = $event;
      },
      "success": _vm.search
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }