<template>
  <el-tabs type="card" class="fm-tabs-card" v-model="activeName">
    <el-tab-pane
      v-for="(item, index) in tabs"
      :label="item.name"
      :name="`${index}`"
      :key="item.component"
    >
      <component
        :is="tabs[activeName].component"
        :estateId="estateId"
        :batchList="batchList"
      ></component>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import CPending from "./_components/pending";
import CCompany from "./_components/company";
import CAgent from "./_components/agent";
import CFollow from "./_components/follow";
export default {
  components: {
    CPending,
    CCompany,
    CAgent,
    CFollow
  },
  data() {
    return {
      tabs: [
        {
          name: "待处理的",
          component: "CPending"
        },
        {
          name: "渠道公司",
          component: "CCompany"
        },
        {
          name: "经纪人",
          component: "CAgent"
        },
        {
          name: "跟进记录",
          component: "CFollow"
        }
      ],
      activeName: "0",
      estateId: null,
      batchList: [] // 批次列表
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-tabs__content {
    height: calc(100% - 40px);
    overflow-y: auto;
  }
}
</style>
