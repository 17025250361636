<template>
  <div class="follow">
    <div class="follow-wrapper">
      <div class="follow-content">
        <f-empty v-if="!list.length && !loading"></f-empty>
        <div class="item" v-for="item in list" :key="item.id">
          <c-follow-item :item="item"></c-follow-item>
        </div>
      </div>
      <div class="follow-sider">
        <div class="filter-box">
          <el-input
            placeholder="搜索渠道公司"
            v-model="queryData.keyword"
            clearable
            @clear="search"
            class="f-search-bar"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
          <div class="title mt20">日期范围</div>
          <el-date-picker
            style="width: 100%;"
            class="mt20"
            v-model="queryLabelData.queryDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="handleDateChange"
          >
          </el-date-picker>
          <div class="title mt20">更多筛选</div>
          <div class="filter mt20">
            <el-select
              v-model="queryLabelData.range"
              placeholder="范围"
              filterable
              clearable
              @change="handleRangeChange"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in rangeOptions"
                :key="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="queryData.type"
              placeholder="类型"
              filterable
              clearable
              @change="search"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in followTypes"
                :key="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getList"
    ></f-pagination>
  </div>
</template>

<script>
import { getMechanismFollow, getMechanismExtList } from "@/api/channel.js";
import CFollowItem from "./_components/followItem";
export default {
  components: { CFollowItem },
  data() {
    return {
      // 跟进类型
      followTypes: [
        { id: 1, name: "拜访" },
        { id: 2, name: "培训" },
        { id: 4, name: "其他" }
      ],
      // 范围条件选项
      rangeOptions: [
        { name: "全部", id: null },
        {
          name: "我创建的",
          id: 2,
          key: "queryType"
        }
        // { name: "我参与的", id: 1, key: "queryType" }
      ],
      // 跟进
      queryLabelData: {
        range: "",
        queryDate: ""
      },
      queryData: {
        type: null, // 1：拜访， 2：培训， 3：日志，4：其它
        followDateStart: "",
        followDateEnd: "",
        keyword: "",
        pageNum: 1,
        pageSize: 5
      },
      options: [],
      total: 0,
      list: [],
      loading: true
    };
  },
  created() {
    this.getList();
    this.getMechanismExtList();
  },
  methods: {
    handleDateChange(date) {
      const [followDateStart, followDateEnd] = date || [];
      Object.assign(this.queryData, {
        followDateStart: followDateStart
          ? this.$time(followDateStart).format("YYYY-MM-DD 00:00:00")
          : "",
        followDateEnd: followDateEnd
          ? this.$time(followDateEnd).format("YYYY-MM-DD 23:59:59")
          : ""
      });
      this.search();
    },
    handleRangeChange(val) {
      const { key, name, id } =
        this.rangeOptions.find(item => item.id == val) || {};
      if (key == "joinUserIds") {
        this.queryData.joinUserIds = [id];
        this.queryData.queryType = null;
      } else if (key == "queryType") {
        this.queryData.queryType = id;
        this.queryData.joinUserIds = [];
      } else {
        this.queryData.joinUserIds = [];
        this.queryData.queryType = null;
      }
      this.search();
    },
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    async getList() {
      const res = await getMechanismFollow(this.queryData);
      if (res) {
        const { total, list } = res;
        this.total = total;
        this.list = list;
      }
    },
    async getMechanismExtList() {
      const res = await getMechanismExtList();
      this.loading = false;
      if (res) {
        this.rangeOptions = [
          ...this.rangeOptions,
          ...res.map(item => {
            return {
              id: item.bizId,
              name: item.bizName,
              key: "joinUserIds"
            };
          })
        ];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.follow {
  margin-top: -15px;
}
.follow-wrapper {
  display: flex;
  justify-content: flex-start;
  .follow-content {
    flex: 1;
    padding-top: 25px;
  }
  .follow-sider {
    width: 350px;
    .filter {
      @include flex-yc;
      column-gap: 15px;
    }
  }
}
.filter-box {
  position: sticky;
  top: -25px;
  border-left: 1px solid #e4e7ed;
  padding: 25px 5px 0 25px;
}
</style>
